import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleLogin: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleSignUp:() =>void;
  handleMagicLink: (email: string) => void;
  handleVerifyMail:() =>void;
  isMobileScreen: boolean;
  isOpen: boolean;
  onSignUpClose: () => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  firstNameValue: string;
  emailValue: string;
  errorMessage:string;
  emailError:boolean;
  agreeConditions:boolean;
  checkError:boolean;
  firstnameError:boolean;
  showPassField:boolean;
  passwordValue:string;
  visiblePass:boolean;
  userDetails:{
    id:string;
    type:string;
    attributes:{
      first_name:string;
      email:string;
      activated:boolean;
      account_type:{
        id:number;
        name:string;
      }
    }
  };
  meta:{
    token:string;
  };
  errorPopup:boolean;
  isLoaderOpen: boolean;
  activeButton: "signup" | "email";
  activeScreen: "signup" | "magic" | "resend";
  magicLinkError: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  createAccountPassId:string = "";
  magicLinkVerificationId: string = "";
  verifyEmailId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      firstNameValue:"",
      emailValue:"",
      errorMessage:"",
      emailError:false,
      agreeConditions:true,
      // ABOVE TO BE MODYFIED AS false 
      checkError:false,
      firstnameError:false,
      showPassField:false,
      passwordValue:"",
      visiblePass:false,
      userDetails:{
        id:'',
        type:'',
        attributes:{
          first_name:'',
          email:'',
          activated:false,
          account_type:{
            id:0,
            name:''
          }
        }
      },
      meta:{
        token:''
      },
      errorPopup:false,
      isLoaderOpen: false,
      activeButton: "email",
      activeScreen: "signup",
      magicLinkError: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.handleResponseMessage(message);

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleErrors = (responseJson: string | Record<string,string[]>) => {
    if (responseJson === "The entered email id is already registered. Please login") {
      this.setState({
        errorMessage: responseJson,
        emailError: true
      })
      return;
    } 
    
    if (typeof responseJson !== "string") {
      if(responseJson.first_name){
        this.setState({
          errorMessage: `First name ${responseJson.first_name.at(0)}`,
          firstnameError: true
        })
      }          
      if(responseJson.password){
        this.setState({
          emailError: true,
          errorMessage: `Password ${responseJson.password.at(0)}`
        })
      }
    }
  }

  handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstNameValue: event.target.value, errorMessage: "", firstnameError: false, emailError: false })
  }
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ emailValue: event.target.value, errorMessage: "", emailError: false, firstnameError: false })
  }

  handleNext = () =>{
    if(this.state.firstNameValue === ""){
      this.setState({
        errorMessage:'First name is required',
        firstnameError:true
      })
    }else if(this.state.emailValue === ""){
      this.setState({
        errorMessage:'Email is required',
        emailError:true
      })
    }else if(!configJSON.emailValidation.test(this.state.emailValue)){
      this.setState({
        emailError:true,
        errorMessage:'Invalid email',

      })
    }else if(!this.state.agreeConditions){
      this.setState({
        checkError:true
      })
    }else{
      this.verifyEmail();
    }
  }
  handleCheck = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({agreeConditions:event.target.checked,checkError:false})
  }
  handlePassword = (event:React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({passwordValue:event.target.value,emailError:false})
  }
  handleClickShowPassword = () => {
    this.setState({
      visiblePass: !this.state.visiblePass,
    });
  };
  handleSignUpPass = () => {
    if(this.state.activeScreen === 'magic'){
      this.loginDirectlyThroughEmail();
      return
    }
    if (this.state.passwordValue === "") {
      this.setState({
        emailError: true,
        errorMessage: configJSON.requiredField,
      })
    } else if (this.state.passwordValue.length < 6) {
      this.setState({
        emailError: true,
        errorMessage: configJSON.passwordLengthError
      })
    } else {
     const message = this.createAccoutPassAPICall({
        data: {
          type: "email_account",
          attributes: {
            first_name: this.state.firstNameValue,
            email: this.state.emailValue,
            password: this.state.passwordValue,
          }
        }
      })
      this.setState({isLoaderOpen: true, activeButton: "signup"})
      this.createAccountPassId = message.messageId;
      runEngine.sendMessage(message.id, message);
    }
  }
  handleBackToSignUp = () =>{
    this.setState({showPassField:false, agreeConditions: true})
  }

  handlePasswordVerification = (message: Message) => {
    const {apiSuccessMessageData} = this.getResponseMessage(message);
    if(apiSuccessMessageData.errors){
      this.handleErrors(apiSuccessMessageData.errors);
      return;
    }

    if(apiSuccessMessageData.data){
      this.setState({
        userDetails: apiSuccessMessageData.data,
        meta: apiSuccessMessageData.meta,
        activeScreen: "resend"
      })
    }
  }

  handleMagicLink = (message: Message) => {
    const { apiSuccessMessageData } = this.getResponseMessage(message);
    if (apiSuccessMessageData.message === "Magic link sent successfully") {
      this.props.handleMagicLink(this.state.emailValue);
      this.setState({activeScreen: "magic"})
      return;
    } 

    if(apiSuccessMessageData.error){
      this.setState({magicLinkError: apiSuccessMessageData.error})
    }
  }

  handleResponseMessage = (message: Message) => {
    const { apiResponseData, apiErrorMessageData } = this.getResponseMessage(message);

    try {
      this.setState({isLoaderOpen: false});
      if (!apiErrorMessageData) {
        (apiResponseData === this.createAccountPassId) && (this.handlePasswordVerification(message));
        (apiResponseData === this.magicLinkVerificationId) && (this.handleMagicLink(message));
        (apiResponseData === this.verifyEmailId) && (this.handleVerifyEmail(message));
      }
    }
    catch (error) {
      this.setState({isLoaderOpen: false});
    }

  }

  loginDirectlyThroughEmail = () => {
    const bodyData = {
      data: {
        attributes: {
          email: this.state.emailValue,
        },
      }
    }

    const magicLinkMessage = this.generateRunEngineRequestMessage(configJSON.magicLinkEndpoint, configJSON.postAPIMethod)
    this.magicLinkVerificationId = magicLinkMessage.messageId;

    magicLinkMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    this.setState( {isLoaderOpen: true, activeButton: "email"} );
    runEngine.sendMessage(magicLinkMessage.id, magicLinkMessage);
  }

  getResponseMessage = (message:Message) => {
    const apiResponseData = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiSuccessMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiErrorMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    return ({ apiResponseData, apiSuccessMessageData, apiErrorMessageData });
  }


  
  generateRunEngineRequestMessage = (endPoint: string, method: string) => {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    return requestMessage;
  }
 

  createAccoutPassAPICall = (body:Record<string,Object>) =>{
    const requestMessage = this.generateRunEngineRequestMessage(configJSON.apiEndPointcreateAccount,configJSON.apiMethodTypeAddDetail)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    return requestMessage;
  }

  verifyEmail = () => {
    const verifyEmailMessage = this.generateRunEngineRequestMessage(
      `${configJSON.checkMailEndPoint}?[data][attributes][email]=${this.state.emailValue}`,
      configJSON.validationApiMethodType);
    this.verifyEmailId = verifyEmailMessage.messageId;
    this.setState( {isLoaderOpen: true} );
    runEngine.sendMessage(verifyEmailMessage.id, verifyEmailMessage);
  }

  handleVerifyEmail = (message: Message) => {
    const { apiSuccessMessageData } = this.getResponseMessage(message);

    if(apiSuccessMessageData.errors 
      && apiSuccessMessageData.errors[0].message === "No account registered with this email"){
      this.setState({activeScreen: "signup", showPassField:true})
      return;
    }

    if(apiSuccessMessageData.message === "Account activated"){
      this.setState({
        activeScreen: "signup",
        emailError: true,
        errorMessage: "The entered email id is already registered. Please login"
      })
    }
  }

  handleCloseErrorPopup = () => {
    this.setState({errorPopup:false})
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
