Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "/bx_block_navmenu/navigation_menus";
exports.getApiMethodType = "GET";
exports.postAPIMethod = "POST";
exports.getCategoriesEndPoint = "/bx_block_categories/categories";
exports.cartItemCountEndpoint = "/bx_block_shopping_cart/carts";
exports.getStoreDetailsEndPoint = "/bx_block_website_builder/designs/get_design?id=1";
exports.getCustomerServiceApiEndpoint = "/bx_block_store_details/store_details";
exports.magicLinkEndpoint = "/account_block/accounts/magic_link";


exports.logIn = "Log in";
exports.menu = "Menu";

exports.queryParameters = {
  Category: "[query][category_slug]",
  SubCategory: "[query][sub_category_slug]",
};

exports.noBannerImageDesktopUrl = "https://placehold.co/525x170?text=No+Banner+Image+Added";
exports.noBannerImageMobileUrl = "https://placehold.co/385x350?text=No+Banner+Image+Added";
exports.suggestedSearchEndpoint = "/bx_block_search/suggested_search_result?query=";
exports.searchPlaceholder = "Search";
exports.moreButton = "More";
exports.skeltonItemsLength = 12;
exports.login = "Login";
exports.loginSIgnUp = "Log in / Sign up";
exports.profileTitle = "Profile";
exports.accountTitle = "Account";
exports.logoutTitle = "Log Out";
exports.manageAddressTitle = "Manage Addresses";
exports.orderTitle = "Orders";
// Customizable Area End