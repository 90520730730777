// Customizable Area Start
import _refiner from 'refiner-js';
_refiner('setProject', 'c6b3dbf0-2d3f-11ef-947f-c16f8319374f');  

export const defineUserRefiner = ({
    id, email, name
}: {
    id?: string |number;
    email?: string;
    name?: string
}) =>{
    _refiner('identifyUser', {
        id, 
        email, 
        name, 
    });
}

export const showFormCancelCTARefiner = () => {
    _refiner("showForm", "18d6cff0-2d40-11ef-a87c-2fcc995e4588");
}

export const showFormCSATRefiner = () => {
    _refiner("showForm", "0df236f0-2d40-11ef-9983-8f7f6a04c794");
}
// Customizable Area End