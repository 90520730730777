import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Checkbox,
  Grid,
  TextField,
  InputAdornment,
  Snackbar,
  IconButton,
  Modal,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { checkbox, checkedCheckbox } from './assets'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Alert from "@material-ui/lab/Alert";
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createTheme({
  typography: {
    fontFamily: 'var(--design-body-text-font)',
  }
});

const PwTextField = styled(TextField)({
  '& fieldset': {
    border: '1px solid #CBD5E1 !important',
  }
})
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
  configJSON
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderMobileCloseButton = () => {
    return this.props.isMobileScreen ? <IconButton onClick={this.props.onSignUpClose} style={{ ...style.closeIcon, ...style.closeIconMobile }}>
      <CloseIcon style={style.blackColor} />
    </IconButton> : <Box></Box>
  }

  renderDeskTopCloseButton = () => {
    return this.props.isMobileScreen ? <Box></Box> : <IconButton onClick={this.props.onSignUpClose} style={style.closeIcon}>
      <CloseIcon style={style.blackColor} />
    </IconButton>
  }


  renderMagicLinkText = () => {
    if (this.state.activeScreen === "magic") {
      return (<>
        {this.props.isMobileScreen && <Typography style={{ ...style.weMailedText, ...style.marginBottom2 }}>{configJSON.weMailedText1}</Typography>}
        {!this.props.isMobileScreen && <Box style={style.marginBottom2}>
          <Typography style={style.weMailedText}>{configJSON.weMailedText}<span style={style.emailtext}>{this.state.emailValue}</span></Typography>
          <Typography style={style.weMailedText}>{configJSON.clickLink}</Typography>
        </Box>
        }
      </>)
    }
    return (<Box style={style.marginBottom2}>
      <Typography style={style.weMailedText}>{configJSON.checkMailText}</Typography>
    </Box>)
  }

  renderMagicLink = () => {
    return (
      <Box className='main_container' style={this.props.isMobileScreen ? { ...style.MainBox, ...style.magicBox } : { ...style.MainBox, ...style.desktopBox }} >
        {this.renderMobileCloseButton()}
        <Grid container style={this.props.isMobileScreen ? { ...style.mobileMainContainer, ...style.magicBox, ...style.magicLinkMainContainer } : style.mainContainer}>
          {this.renderDeskTopCloseButton()}
          <Grid item xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12} style={style.mainHeadinggrid}>
              <Typography style={{ ...style.mainHeading, ...style.marginBottom2 }} data-testid="magic-link">
                {this.state.activeScreen === "resend" ? configJSON.verifyMailText : configJSON.magicLinkSent}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography style={style.checkMail}>{configJSON.checkMail}</Typography>
              {this.renderMagicLinkText()}
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={style.mainButtongrid}>
            <Button disableElevation data-testid="resend-button" variant="contained" style={style.buttonText} onClick={this.handleSignUpPass} disabled={this.state.isLoaderOpen}>
              {this.state.isLoaderOpen ? <CircularProgress data-testid='circle' size={27} color="inherit" /> : configJSON.resendMailButton}</Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderPasswordFeild = () => {
    return (<>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography data-testid="pass-label" style={style.firstNametext}>{configJSON.labelPassword}</Typography>
        <PwTextField
          variant="outlined"
          fullWidth
          value={this.state.passwordValue}
          data-testid="passwrd-field"
          onChange={this.handlePassword}
          placeholder={configJSON.placeholderPass}
          type={this.state.visiblePass ? "text" : "password"}
          style={style.passwordField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" data-testid="visible-pass" onClick={this.handleClickShowPassword}>
                {this.state.visiblePass ? (
                  <VisibilityOutlinedIcon style={style.eyeIcon} />
                ) : (
                  <VisibilityOffOutlinedIcon style={style.eyeIcon} />
                )}
              </InputAdornment>
            ),
            style: style.inputProps
          }}
          inputProps={{ style: style.passInputProps }}
          error={this.state.emailError}
          helperText={this.state.emailError && this.state.errorMessage}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={style.mainButtongrid}>
        <Button disableElevation variant="contained" data-testid="signup-button" style={style.buttonText} onClick={this.handleSignUpPass} disabled={this.state.isLoaderOpen}>
          {(this.state.isLoaderOpen && this.state.activeButton === "signup") ? <CircularProgress size={27} color="inherit" /> : configJSON.signUpPassButton}
        </Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography style={this.props.isMobileScreen ? { ...style.backtotext, ...style.backButttonTextAlign, } : style.backtotext}>{configJSON.backTo}<span style={style.loginSpan} data-testid="back-to-login" onClick={this.handleBackToSignUp}>{configJSON.btnTextSignUp}</span></Typography>
      </Grid>
    </>)
  }

  renderEmailFeild = () => {
    return (<>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {this.state.checkError &&
          <Box style={style.agreeError}>
            <Typography data-testid="terms-error">{configJSON.pleaseAgreeError}</Typography>
          </Box>
        }
        <Typography style={style.firstNametext}>{configJSON.labelFirstName}</Typography>
        <input
          placeholder={configJSON.labelFirstName}
          style={{ ...style.firstNametextfield, border: this.state.firstnameError ? '1px solid #F87171' : '1px solid #CBD5E1' }}
          value={this.state.firstNameValue}
          onChange={this.handleFirstName}
          data-testid="first-name-field"
        />
        {this.state.firstnameError &&
          <Typography data-testid="firstname-errormessage" style={style.errorMessage}>{this.state.errorMessage}</Typography>
        }
        <Typography style={style.firstNametext}>{configJSON.placeHolderEmail}</Typography>
        <input
          style={{ ...style.firstNametextfield, border: this.state.emailError ? '1px solid #F87171' : '1px solid #CBD5E1' }}
          placeholder={configJSON.placeHolderEmail}
          value={this.state.emailValue}
          onChange={this.handleEmailChange}
          data-testid="email-field"
        />
        {this.state.emailError &&
          <Typography style={style.errorMessage} data-testid="email-errorMessage">{this.state.errorMessage}</Typography>
        }
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{...style.mainTermsgrid, marginTop: '10px'}}>
        {/* ABOVE MARGIN TO BE REMOVED */}
        {/* <Checkbox
          style={style.checkboxPadding}
          icon={checkbox}
          value={this.state.agreeConditions}
          defaultChecked={this.state.agreeConditions}
          onChange={this.handleCheck}
          data-testid="checkbox-field"
          checkedIcon={checkedCheckbox('var(--design-primary-color)')}
        />
        <Typography style={style.agreeText}>{configJSON.agreeWith} <span style={style.agreeTextspan}>{configJSON.privacyPolicy}</span> {configJSON.and} <span style={style.agreeTextspan}>{configJSON.termCondition}</span></Typography> */}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={style.mainButtongrid}>
        <Button disableElevation variant="contained" data-testid="next-button" style={style.buttonText} onClick={this.handleNext} disabled={this.state.isLoaderOpen}>{
          configJSON.nextButton
        }</Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={style.mainlogingrid}>
        <Typography style={style.loginMaintext}>{configJSON.alreadyAccount}<span data-testid="login-span" style={style.loginSubtext} onClick={this.props.handleLogin}>{configJSON.login}</span></Typography>
      </Grid>
    </>)
  }

  renderModalComponent = () => {
    return (
      <Box style={this.props.isMobileScreen ? { ...style.MainBox, ...style.magicBox } : { ...style.MainBox, ...style.desktopBox }} >
        {this.renderMobileCloseButton()}
        <Grid container style={this.props.isMobileScreen ? { ...style.mobileMainContainer, ...style.backgroundNone } : style.mainContainer}>
          {this.renderDeskTopCloseButton()}
          <Grid item lg={12} md={12} sm={12} xs={12} style={style.mainHeadinggrid}>
            <Typography style={style.mainHeading} data-testid="sign-up">{configJSON.btnTextSignUp}</Typography>
            {this.state.magicLinkError &&
              <Box style={style.agreeError}>
                <Typography data-testid="terms-error">{this.state.magicLinkError}</Typography>
              </Box>
            }
            {this.state.showPassField && <Typography style={style.emailText}>{this.state.emailValue}</Typography>}
          </Grid>
          {this.state.showPassField ? this.renderPasswordFeild() : this.renderEmailFeild()}
        </Grid>
      </Box>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Modal
          open={this.props.isOpen}
          BackdropProps={{
            style:
              this.props.isMobileScreen ? style.mobileBackground : style.desktopBackground
          }}
          disableAutoFocus
          disableEnforceFocus
        >
          {(this.state.activeScreen === "signup") ?  this.renderModalComponent() : this.renderMagicLink() }

        </Modal>
        <Snackbar data-testid="error-toast" open={this.state.errorPopup} autoHideDuration={2000} onClose={this.handleCloseErrorPopup}>
          <Alert onClose={this.handleCloseErrorPopup} severity="error">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const style = {
  checkboxPadding: {
    padding: '1px 6px 1px 0',
  },
  emailtext: {
    fontWeight: 600,
    color: 'var(--design-primary-color)',
    marginLeft: '5px',
    fontFamily: "var(--design-body-text-font)",
  },
  checkMail:{
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left" as 'left',
    color:"var(--design-secondary-color)",
    margin:'10px 0',
    fontFamily: "var(--design-body-text-font)",
  },
  marginBottom2: {
    marginBottom: "2.5rem"
  },
  weMailedText: {
    color: 'var(--design-secondary-color)',
    fontFamily: "var(--design-body-text-font)",
    whiteSpace: 'pre-wrap',
  },
  magicLinkMainContainer: {
    padding: "0px 20px 20px 20px",
    display: "flex",
    backgroundColor: "transparent",
  },
  longPassword:{
    fontWeight:600,
    color:'var(--design-secondary-color)',
    margin:'10px 0',
    textAlign:'center' as 'center'
  },
  backButttonTextAlign: {
    textAlign: "center",
  },
  maginlinkdetails:{
    fontSize:'14px',
    lineHeight:'24px',
    color:'var(--design-secondary-color)',
    textAlign:'center' as 'center',
    marginBottom:'20px'
  },
  mailmeButton:{
    background:'#F0E5FF',
    borderRadius:'8px',
    fontWeight:700,
    color:'var(--design-primary-color)',
    textTransform:'capitalize' as 'capitalize',
    width:'100%',
    boxShadow:'none',
    padding:'15px'
  },
  hrLine:{
    width:'80%',
    height:0,
    color:'#E2E8F0'
  },
  orText:{
    fontSize:'14px',
    fontWeight:700,
    color:'#64748B'
  },
  dividerBox:{
    display:'flex',
    alignItems:'center',
    gap:'20px',
    margin:'20px 0'
  },
  backgroundNone: {
    backgroundColor: "transparent",
  },
  mainContainer:{
    padding: "40px",
    width: "min(440px, 100%)",
    backgroundColor: "#fff",
    borderRadius: "12px",
    position: "relative" as "relative",
    margin: "0 auto",
  },
  mobileMainContainer: {
    paddingTop: "40px",
    width: "min(440px, 100%)",
    backgroundColor: "#fff",
    borderRadius: "12px",
    position: "relative" as "relative",
    margin: "0 auto",
  },
  mobileBackground: {
    backgroundColor:'#ffffff',
    marginTop: "48px",
    height: "calc(100% - 48px)",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "0 20px",
  },
  desktopBackground: { 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  closeIcon: {
    position: "absolute" as "absolute",
    right: "1%", 
    top: "1%"
  },
  closeIconMobile: {
    top: "3%",
    right: "3%",
    zIndex: 1
  },
  blackColor: {
    color: "#000000",
  },
  magicBox: {
    height: "calc(100% - 48px)",
    display: 'block',
    marginTop: "1.8rem",
  },
  desktopBox: {
    width: "100%",
    height: "100vh",
    marginTop: "0",
  },
  MainBox: {
    boxShadow: '24px',
    border:'none',
    borderRadius:"12px",
    padding: '20px',
    display:"flex",
    overflow:'scroll' as 'scroll',
    justifyContent:"center",
    alignItems:"center",
    height: "396px",
    position: "relative" as "relative",
  },
  mainHeadinggrid: {
    margin: "3% 0"
  },
  mainHeading: {
    fontFamily: "var(--design-header-text-font)",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    textAlign: "left" as 'left',
    color: "var(--design-secondary-color)"

  },
  firstNametext: {
    fontFamily: "var(--design-body-text-font)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "32px",
    textAlign: "left" as 'left',
    color: "var(--design-secondary-color)",
    marginTop: ".5rem",
  },
  firstNametextfield: {
    fontFamily: "var(--design-body-text-font)",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "32px",
    textAlign: "left" as 'left',
    margintop: "1%",
    width: "100%",
    borderRadius: '8px',
    padding: '10px',
    color: "#000000",
    "&:placeholder": {
      color: "#64748B",
    },
    outline: "none",
  },
  mainTermsgrid: {
    display: "flex",
    margin: "15px 0px",
    alignItems: "start",
    marginTop: "2.5rem",
  },
  mainButtongrid: {
    display: "flex",
    justifyContent: "center",
  },
  buttonText: {
    background: "linear-gradient(90deg,var(--design-accent-color),var(--design-primary-color))",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    width: "100%",
    height: "fit-content",
    fontFamily: "var(--design-body-text-font)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#FFFFFF",
    textTransform: "none" as "none",
    alignSelf: "flex-end",
    minHeight: "52px",
  },
  mainlogingrid: {
    margin: "3% 0 2%"
  },
  loginMaintext: {
    fontFamily: "var(--design-body-text-font)",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "var(--design-secondary-color)"
  },
  loginSubtext: {
    fontFamily: "var(--design-body-text-font)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "var(--design-primary-color)",
    marginLeft: "1%",
    cursor: 'pointer'
  },
  agreeText: {
    fontFamily: "var(--design-body-text-font)",
    fontSize: "14px",
    fontWeight: 400,
    color: "var(--design-secondary-color)",
    marginRight: '10px'
  },
  agreeTextspan: {
    fontFamily: "var(--design-body-text-font)",
    fontSize: "14px",
    fontWeight: 400,
    color: "var(--design-primary-color)",
    textDecoration: "underline  var(--design-primary-color)",
  },
  checkedIcon: {
    width: '22px',
    height: '23px'
  },
  errorMessage: {
    fontSize: '12px',
    color: '#DC2626',
    margin: '2px 0 5px'
  },
  agreeError: {
    background: '#FEE2E2',
    color: '#DC2626',
    borderLeft: '4px solid #DC2626',
    padding: '12px',
    fontSize: '12px',
    margin: '10px 0',
    borderRadius: '5px'
  },
  emailText: {
    fontWeight: 500,
    color: 'var(--design-primary-color)',
    marginTop: '15px'
  },
  passwordField: {
    background: '#fff',
    paddingBottom: '20px'
  },
  eyeIcon: {
    color: '#94A3B8',
    cursor: 'pointer'
  },
  inputProps: {
    borderRadius: "8px",
    borderColor: '#CBD5E1',
    outline: 'none',
  },
  passInputProps: {
    padding: '17px'
  },
  backtotext: {
    color: 'var(--design-secondary-color)',
    padding: '15px 0 10px'
  },
  loginSpan: {
    color: 'var(--design-primary-color)',
    paddingLeft: '5px',
    fontWeight: 700,
    cursor: 'pointer'
  },
  MoBileMainBox: {
    padding: '40px 20px'
  }
};
// Customizable Area End